<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">
        <ConfigurationNav />
      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm">
          <div class="card-header">
            <h4>Meta field collections</h4>
          </div>
          <div
            v-if="Object.keys(collections.data).length !== 0"
            class="card-body"
          >
            <data-table
              :headers="headers()"
              sort-url="/order/dynamicFieldCollection"
              :method="'get'"
              :data="collections.data"
            >
              <template #order_number="{ item }">
                {{ item.type }}
              </template>
              <template #date="{ item }">
                {{ item.source }}
              </template>
              <template #actions="{ item }">
                <router-link
                  :to="{ name: 'meta.fields.edit', params: { id: item.id } }"
                  class="btn btn-sm btn-outline-secondary"
                >
                  Edit
                </router-link>
              </template>
            </data-table>
          </div>
          <div v-else class="card-body">
            <p class="text-muted">
              This page shows a list of the most meta field collections, which
              is updated when the orders are recieved.
            </p>
            <div class="p-4 rounded border-zip text-center">
              <p class="mb-0">
                No meta field collections found
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import DataTable from "@/components/DataTable/DataTable.vue";
import Default from "@/layouts/default.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";

export default {
  components: {
    DataTable,
    Default,
    ConfigurationNav,
  },
  beforeRouteEnter(to, from, next) {
    let uri = "/order/dynamicFieldCollection";
    jsonAPI.get(uri).then((response) => {
      next((vm) => {
        vm.collections = response.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      collections: {
        data: [],
        meta: {
          total: 0,
        },
        links: {},
      },
    };
  },
  methods: {
    headers() {
      return [
        {
          title: "Field relation",
          key: "type",
          sortable: true,
          sortKey: "type",
        },
        { title: "Source", key: "source", sortable: true, sortKey: "source" },
        { title: "Actions", key: "actions" },
      ];
    },
  },
};
</script>
